var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      attrs: {
        id: _vm.groupId,
        label: _vm.def.label,
        "label-for": _vm.inputId,
        description: _vm.def.description
      }
    },
    [
      _vm.def.type == "readonly"
        ? _c("div", { attrs: { id: _vm.inputId } }, [
            _vm._v(" " + _vm._s(_vm.value) + " ")
          ])
        : _vm._e(),
      ["text", "email"].includes(_vm.def.type)
        ? _c("b-form-input", {
            attrs: {
              id: _vm.inputId,
              type: _vm.def.type,
              required: _vm.def.required == true,
              placeholder: _vm.def.placeholder
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        : _vm._e(),
      _vm.def.type == "select"
        ? _c("b-form-select", {
            attrs: {
              id: _vm.inputId,
              type: _vm.def.type,
              options: _vm.def.options,
              required: _vm.def.required == true,
              placeholder: _vm.def.placeholder
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        : _vm._e(),
      _vm.def.type == "checkbox"
        ? _c("b-form-checkbox", {
            attrs: {
              id: _vm.inputId,
              type: "checkbox",
              required: _vm.def.required == true,
              value: "1",
              "unchecked-value": "0"
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        : _vm._e(),
      _vm.def.type == "v-select"
        ? _c("v-select", {
            staticStyle: { "background-color": "white" },
            attrs: { id: _vm.inputId, options: _vm.def.options },
            scopedSlots: _vm._u(
              [
                {
                  key: "search",
                  fn: function(ref) {
                    var attributes = ref.attributes
                    var events = ref.events
                    return [
                      _c(
                        "input",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "vs__search",
                              attrs: { required: _vm.vSelectRequired }
                            },
                            "input",
                            attributes,
                            false
                          ),
                          events
                        )
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              1426886625
            ),
            model: {
              value: _vm.vSelectValue,
              callback: function($$v) {
                _vm.vSelectValue = $$v
              },
              expression: "vSelectValue"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }