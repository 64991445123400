<template>
  <b-form-group
    :id="groupId"
    :label="def.label"
    :label-for="inputId"
    :description="def.description">

    <div
      v-if="def.type == 'readonly'"
      :id="inputId">
      {{ value }}
    </div>

    <b-form-input
      v-if="['text', 'email'].includes(def.type)"
      :id="inputId"
      v-model="value"
      :type="def.type"
      :required="def.required == true"
      :placeholder="def.placeholder"
    />

    <b-form-select
      v-if="def.type == 'select'"
      :id="inputId"
      v-model="value"
      :type="def.type"
      :options="def.options"
      :required="def.required == true"
      :placeholder="def.placeholder"
    />

    <b-form-checkbox
      v-if="def.type == 'checkbox'"
      :id="inputId"
      v-model="value"
      type="checkbox"
      :required="def.required == true"
      value="1"
      unchecked-value="0"
    />

    <v-select
      v-if="def.type == 'v-select'"
      v-model="vSelectValue"
      :id="inputId"
      :options="def.options"
      style="background-color: white;"
    >
      <template #search="{attributes, events}">
        <input
          class="vs__search"
          :required="vSelectRequired"
          v-bind="attributes"
          v-on="events"
        />
      </template>
    </v-select>

  </b-form-group>
</template>

<script>
  import vSelect from 'vue-select'

  export default {
    name: 'FormFieldV2',
    components: {
      vSelect,
    },
    data () {
      return {
        vSelectValue: '',
      }
    },
    props: {
      form: Object,
      name: String,
    },
    watch: {
      vSelectValue () {
        if (this.vSelectValue) {
          this.value = this.vSelectValue.key
        } else {
          this.value = ''
        }

      }
    },
    computed: {
      groupId () { return this.name + '-group'; },
      inputId () { return this.name + '-input'; },
      def () { return this.form.fields[this.name] },
      value: {
        get () { 
          console.log('getting value', value)
          let value = this.form.values[this.name]
          return value
        },
        set (val) {
          this.$emit('input', this.name, val)
         },
      },
      vSelectRequired: function () {
        return this.value == undefined || this.value == ''
      }
    },
    mounted () {
      console.log(this.name)
    }
  }
</script>

<style lang="scss" scoped>

</style>